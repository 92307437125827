import request from 'superagent';
import { SET_STORY_DUE_DATE } from './types';
import processingStoryProperty from './processingStoryProperty';
import clearStoryStatusProperty from './clearStoryStatusProperty';
import StoryChief from '../../storychief/index';
import updateStatusError from '../../storychief/actions/updateStatusError';

function setStoryDueDateAsync(dueDate) {
  return {
    type: SET_STORY_DUE_DATE,
    payload: dueDate,
  };
}

export default function setStoryDueDate(storyId, dueDate) {
  const property = 'language';

  return (dispatch) => {
    dispatch(processingStoryProperty(property));
    request
      .put(`${StoryChief.apiBasePath}/stories/${storyId}/due-date`)
      .set('Accept', 'application/json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('X-CSRF-TOKEN', StoryChief.csrfToken)
      .set('X-Socket-ID', window.Echo.socketId())
      .send({
        due_at: dueDate ? dueDate.toUnixInteger() : '',
      })
      .end((err, res) => {
        if (res && res.ok) {
          const newStoryDueDate = res.body;
          dispatch(setStoryDueDateAsync(newStoryDueDate));
          dispatch(clearStoryStatusProperty(property));
        } else {
          dispatch(updateStatusError(res, err));
        }
      });
  };
}
